<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "containers",
              "add-user-to-container",
              "add-user-to-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('containers', 'add-user-to-container', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section pill" v-if="containers && containers.length">
      <li
        class="clebex-item-section-item"
        v-for="container in containers"
        :key="container.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedContainer(container)"
        >
          <span class="label">{{ container.name }}</span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedContainers &&
                  selectedContainers.length &&
                  selectedContainers.filter(item => item.id === container.id)
                    .length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
export default {
  name: "AddAssignableContainersToUser",
  data() {
    return {
      searchQuery: ""
    };
  },
  created() {
    if (this.actionType === "add") {
      this.getUserAssignableContainers({
        mode: "create"
      });
    } else {
      this.getUserAssignableContainers({
        mode: "create"
      });
      this.getUserAssignableContainers({
        mode: "update"
      });
    }
  },
  computed: {
    ...mapState("user", ["listOfAssignableContainers", "selectedContainers"]),
    containers() {
      const query = this.searchQuery;
      if (
        this.listOfAssignableContainers &&
        this.listOfAssignableContainers.data &&
        this.listOfAssignableContainers.data.length
      ) {
        if (query && query.length > 1) {
          return this.listOfAssignableContainers.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.listOfAssignableContainers.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("user", [
      "getUserAssignableContainers",
      "setSelectedContainer"
    ])
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      required: true
    }
  }
};
</script>
